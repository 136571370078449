
async function onLoad(rut, clientId, token, data, flow, tsFunction) {
  var rutCliente = rut;
  var clientId = clientId;
  var authToken = token;

  const widgetConfig = {
    enableSms: data.enabledSMS,
    enableEmail: data.enabledMail,
    enableCoordenadas: data.enabledCDC,
    rut: rutCliente,
    clientId: clientId,
    authToken: authToken,
    contextData: [{ name: "flujo", value: flow }],
    callback: tsFunction
  };

  if (areFilesLoaded()) {
    widget1 = testWidget.widgets.autWidget.new(widgetConfig);
    widget1.render(document.getElementById("element"));
    return true;
  } else {
    return false;
  }
}

function areFilesLoaded() {
  try {
    if (testWidget) {
      return true;
    }
  } catch (error) {
    return false;
  }
}

async function onLoadV2(header, data, signature, tsFunction) {
    const widgetConfig = {
        widgetConfig: {
            header	    : header,
            data	    : data,
            signature   : signature,
            callback    : tsFunction
        }  
      };

    if (areFilesLoadedV2()) {  
        WidgetBR.widget.load(widgetConfig, document.getElementById("contenedor"));
        return true;
    } else {
        return false;
    }
}
  
function areFilesLoadedV2() {
    try {
        if (WidgetBR) {
            return true;
        }
    } catch (error) {
        return false;
    }
}

async function onLoadV3(header, data, signature, tsFunction) {
    const widgetConfig = {
        header	    : header,
        data	    : data,
        signature   : signature,
        callback    : tsFunction
    };
  
    if (areFilesLoadedV3()) {
        authWidget.widget.load(widgetConfig, document.getElementById("element"));
        return true;
    } else {
        return false;
    }
  }
  
  function areFilesLoadedV3() {
    try {
        if (authWidget) {
            return true;
        }
    } catch (error) {
        return false;
    }
  }
  
